/** ***************************************************************************
 * Organisms / Crags / Filters
 *************************************************************************** */

import { groupBy } from '../../../../src/scripts/utils';

window.addEventListener('julia:init', () => {
  const panel = document.getElementById('hns-c-crag-filters');

  if (!panel) {
    return;
  }

  panel.messages = {
    ready: panel.getAttribute('data-label-ready'),
    busy: panel.getAttribute('data-label-busy'),
    done: panel.getAttribute('data-label-done'),
    reset: panel.getAttribute('data-label-reset'),
    error: panel.getAttribute('data-message-error'),
    found: panel.getAttribute('data-message-found'),
    notFound: panel.getAttribute('data-message-not-found'),
  };

  panel.form = panel.querySelector('form');
  panel.resetButton = panel.form.querySelector(':scope .hns-c-crag-filters__buttons > .hns-u-button');
  panel.submitButton = panel.form.querySelector(':scope .hns-c-crag-filters__buttons > [type=submit]');
  panel.resultsContainer = document.getElementById('search-results');
  panel.formData = new FormData(panel.form);

  panel.querySelectorAll('input[name]').forEach((checkbox) => checkbox.addEventListener('change', (event) => {
    panel.formData = new FormData(panel.form);
  }));

  panel.updateHeight = function() {
    const node = this.querySelector(':scope > form');
    let value = node.scrollHeight;

    node.querySelectorAll(':scope > *').forEach((child) => {
      value += child.scrollHeight;
    });
    this.style.setProperty('--height-content', `${value}px`);
  };

  panel.open = function() {
    const toggle = this.querySelector(':scope > .hns-c-toggle');

    this.setAttribute('data-status', 'open');
    toggle.checked = true;
  };

  panel.close = function() {
    const toggle = this.querySelector(':scope > .hns-c-toggle');

    this.setAttribute('data-status', 'closed');
    toggle.checked = false;
  };

  panel.toggle = function() {
    this.getAttribute('data-status') === 'open' ? this.close() : this.open();
  };

  panel.message = function(message) {
    const messageTemplate = this.querySelector(':scope > template.search-message');
    const messageNode = messageTemplate.content.cloneNode(true);

    messageNode.querySelector('p').innerText = message;
    this.messageContainer.appendChild(messageNode)
  }

  panel.search = function() {
    const queryString = new URLSearchParams(this.formData);
    const url = `${this.form.action}?${queryString}`
    const resultTemplate = this.querySelector(':scope > template.search-results-crag');
    const categoryTemplate = this.querySelector(':scope > template.search-results-category');

    this.messageContainer.innerHTML = '';

    fetch(url, {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }
    }).then(
      (response) => response.json(),
    ).then((data) => {

      const groupByCategory = groupBy('category_name');
      const categories = groupByCategory(data);
      let found = 0;

      this.resultsContainer.innerHTML = '';
      for (let [category, crags] of Object.entries(categories)) {
        const categoryNode = categoryTemplate.content.cloneNode(true);
        const cragContainer = categoryNode.querySelector('.hns-c-card-holder');

        categoryNode.querySelector('h2').innerText = category;
        crags.forEach((crag) => {
          const cragNode = resultTemplate.content.cloneNode(true);
          const cardNode = cragNode.querySelector('.hns-c-crag-card');
          const titleNode = cragNode.querySelector('h3 > a');
          const difficultyNode = cragNode.querySelector('dl > :nth-child(2) dd');

          found++;

          cardNode.setAttribute('id', crag.id);
          if (crag.images) {
           cardNode.style.setProperty(
             '--hns-c-crag-card--BackgroundImage',
             `url(${crag.images.card})`
           );
          }

          titleNode.innerText = crag.name;
          titleNode.setAttribute('href', crag.url);

          cragNode.querySelector('.hns-c-crag-card__subtitle').innerText = crag.city;

          cragNode.querySelector('dl > :first-child dd').innerText = crag.route_count;

          if (crag.difficulty) {
            const lower = crag.difficulty.lower;
            const upper = crag.difficulty.upper;
            if (lower == upper) {
              difficultyNode.innerText = lower.name;
            } else {
              difficultyNode.innerText = `${lower.name} ➝ ${upper.name}`;
            }
          } else {
            difficultyNode.innerText = '';
          }

          cragContainer.appendChild(cragNode);
        });
        this.resultsContainer.appendChild(categoryNode);
      }
      this.message(found > 0 ? this.messages.found : this.messages.notFound);
    }).catch((error) => {
      console.log(error.message);
      this.message(this.messages.error);
    }).finally(() => {
      this.messageContainer.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    });

  }

  panel.reset = function() {
    this.form.querySelectorAll('input').forEach((input) => {
      input.checked = false;
    });
    panel.search();
  }

  panel.init = function() {
    const header = this.querySelector(':scope > header');
    const toggle = this.querySelector(':scope > .hns-c-toggle');
    const label = header.querySelector(':scope > label');

    this.setAttribute('data-status', toggle.checked ? 'open' : 'closed');
    this.updateHeight();

    label.addEventListener('click', (event) => {
      event.preventDefault();
      this.toggle();
    });

    this.form.addEventListener('submit', (event) => {
      event.preventDefault();
      panel.search();
    });

    this.resetButton.addEventListener('click', (event) => {
      event.preventDefault();
      panel.reset();
    });

    this.messageContainer = document.createElement('div');
    this.messageContainer.classList.add('messages');
    this.resultsContainer.parentNode.insertBefore(this.messageContainer, this.resultsContainer);

  }

  panel.init();

  window.addEventListener('resize', (event) => {
    panel.updateHeight();
  });
});

/** ***************************************************************************
 * Julia utilities
 *************************************************************************** */

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];

    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

function pauseAnimations() {
  // document.body.classList.add("hns-m-loading");
}

function resumeAnimations() {
  // document.body.classList.remove("hns-m-loading");
}

export { sleep, groupBy, pauseAnimations, resumeAnimations };

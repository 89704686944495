/** ***************************************************************************
 * Organisms / CMS plugins / Image gallery
 *************************************************************************** */

window.addEventListener('julia:init', () => {
  const initialize = function() {

    document.querySelectorAll('.hns-c-gallery').forEach((gallery) => {
      function clickHandler(event) {
        event.preventDefault();
        gallery.dialog.splide.go(index);
        gallery.dialog.openModal();
      }

      gallery.dialog = gallery.querySelector('.hns-c-modal.hns-m-slider');

      gallery.querySelectorAll(':scope > a').forEach((link, index) => {
        link.onclick = (event) => {
          event.preventDefault();
          gallery.dialog.splide.go(index);
          gallery.dialog.openModal();
        };
      });
    });

  };

  const destroy = function() {
    document.querySelectorAll('.hns-c-gallery').forEach((gallery) => {
      gallery.dialog = undefined;
      gallery.querySelectorAll(':scope > a').forEach((link) => link.onclick = undefined);
    });
  };

  window.JULIA.initializers['hns-c-gallery'] = initialize;
  window.JULIA.destroyers['hns-c-gallery'] = destroy;

  initialize();

});

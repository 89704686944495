/** ***************************************************************************
 * Organisms / CMS plugins / Call to action
 *************************************************************************** */

window.addEventListener('julia:init', () => {

  function ctaEnterCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const cta = entry.target;

        cta.classList.add('hns-m-visible');
        observer.unobserve(cta);
      }
    })
  }

  const ctaEnterObserver = new IntersectionObserver(ctaEnterCallback, {
    root: null,
    threshold: 0.2
  })

  const initialize = function() {
    document.querySelectorAll('.hns-c-cta').forEach((cta) => {
      cta.classList.add('hns-m-js');
      ctaEnterObserver.observe(cta);
    });
  };

  const destroy = function() {
    document.querySelectorAll('.hns-c-cta').forEach((cta) => {
      ctaEnterObserver.observe(cta);
      cta.classList.remove('hns-m-js');
    });
  };

  window.JULIA.initializers['hns-c-cta'] = initialize;
  window.JULIA.destroyers['hns-c-cta'] = destroy;

  initialize();
});

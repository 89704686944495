/** ***************************************************************************
 * Organisms / CMS plugins / Video player
 *************************************************************************** */

window.addEventListener('julia:init', () => {
  const initialize = function() {
    document.querySelectorAll('.hns-c-video-player').forEach((player) => {
      player.classList.add('hns-m-js');

      player.openButton = player.querySelector(':scope > button');

      player.dialog = player.querySelector(':scope > dialog');

      player.openButton.addEventListener('click', (event) => {
        player.dialog.openModal();
      });

    });
  };

  const destroy = function() {
    document.querySelectorAll('.hns-c-video-player').forEach((player) => {
      player.classList.remove('hns-m-js');
    });
  };

  window.JULIA.initializers['hns-c-video-player'] = initialize;
  window.JULIA.destroyers['hns-c-video-player'] = destroy;

  initialize();
});

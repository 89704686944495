/** ***************************************************************************
 * Charlie
 *************************************************************************** */

import { sleep } from '../../../../src/scripts/utils';

window.addEventListener('julia:init', () => {
  function resizeHandler(event) {
    document.querySelectorAll('.hns-c-charlie').forEach((charlie) => {
      charlie.updateHeight();
    });
  }

  const initialize = function() {
    document.querySelectorAll('.hns-c-charlie').forEach((charlie) => {
      charlie.updateHeight = function() {
        const node = this.querySelector(':scope > div');
        let value = node.scrollHeight;

        node.querySelectorAll(':scope > *').forEach((child) => {
          value += child.scrollHeight;
        });
        this.style.setProperty('--height-content', `${value}px`);
        node.style.setProperty('position', 'initial');
      };

      charlie.open = function() {
        const toggle = this.querySelector(':scope > .hns-c-toggle');

        this.parentNode.querySelectorAll(
          ':scope > .hns-c-charlie'
        ).forEach((charlie) => charlie.close());
        this.updateHeight();
        this.setAttribute('data-status', 'open');
        toggle.checked = true;
        // FIXME: this is --hns-global--Duration--sm + 100
        sleep(400).then(() => {
          const header = this.querySelector(':scope > header');
          if (header.getBoundingClientRect().top < 0) {
            header.scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            });
          }
        });
      };

      charlie.close = function() {
        const toggle = this.querySelector(':scope > .hns-c-toggle');

        this.setAttribute('data-status', 'closed');
        toggle.checked = false;
      };

      charlie.toggle = function() {
        this.getAttribute('data-status') === 'open' ? this.close() : this.open();
      };

      charlie.init = function() {
        const header = this.querySelector(':scope > header');
        const label = header.querySelector(':scope > label');
        const toggle = this.querySelector(':scope > .hns-c-toggle');

        this.setAttribute('data-status', toggle.checked ? 'open' : 'closed');
        this.updateHeight();

        header.onclick = (event) => {
          event.preventDefault();
          this.toggle();
        };

        charlie.header = header;

      }

      charlie.init();
    });

    window.addEventListener('resize', resizeHandler);
  };

  const destroy = function() {
    document.querySelectorAll('.hns-c-charlie').forEach((charlie) => {
      window.removeEventListener('resize', resizeHandler);
      if (charlie.header) {
        charlie.header.onclick = undefined;
        charlie.header = undefined;
      }
      charlie.removeAttribute('data-status');
      charlie.updateHeight = undefined;
      charlie.open = undefined;
      charlie.close = undefined;
      charlie.toggle = undefined;
      charlie.init = undefined;

    });
  };

  window.JULIA.initializers['hns-c-charlie'] = initialize;
  window.JULIA.destroyers['hns-c-charlie'] = destroy;

  initialize();
});

/** ***************************************************************************
 * Molecules / Modal / Video
 *************************************************************************** */

document.addEventListener('DOMContentLoaded', () => {

  class VideoPlayer {
    constructor(dialog) {
      this.dialog = dialog;
      this.video = dialog.querySelector(':scope > video');
      this.iframe = dialog.querySelector(':scope > iframe');
      this.youtube = !! this.iframe?.src?.match('youtube');

      if (this.youtube) {
        this.initIframeApi();
        window.addEventListener('youtube:ready', (event) => {
          this.ytPlayer = new window.YT.Player(this.iframe);
        });
      }

      dialog.addEventListener('close', () => {
        this.pause();
      });

      // patch dialog object
      dialog._openModal = dialog.openModal;
      dialog.openModal = function() {
        this._openModal();
        this.player.play();
      }
    }

    _destroy() {
      this.pause();
      this.dialog.openModal = this.dialog._openModal;
    }

    initIframeApi() {
      if (!document.getElementById('iframe-api')) {
        const iframeApi = document.createElement('script');

        iframeApi.src = 'https://www.youtube.com/iframe_api';
        iframeApi.id = 'iframe-api';

        document.querySelector('body').appendChild(iframeApi);

        window.onYouTubeIframeAPIReady = () => {
          window.dispatchEvent(new Event('youtube:ready'));
        };
      }
    }

    play() {
      if (this.ytPlayer) {
        this.ytPlayer.playVideo();
      } else {
        if (this.video) {
          this.video.play();
        }
      }
    }

    pause() {
      if (this.ytPlayer) {
        this.ytPlayer.pauseVideo();
      } else {
        if (this.video) {
          this.video.pause();
        }
      }
    }
  }

  function initialize() {
    document.querySelectorAll('.hns-c-modal.hns-m-video').forEach((dialog) => {
      dialog.player = new VideoPlayer(dialog);
    });
  }

  function destroy() {
    document.querySelectorAll('.hns-c-modal.hns-m-video').forEach((dialog) => {
      if (dialog.player) {
        dialog.player._destroy();
        dialog.player = undefined;
      }
    });
  }

  // window.JULIA.initializers.videoModal = initialize;
  // window.JULIA.destroyers.videoModal = destroy;

  initialize();

});

/** ***********************************************************************************
 * Organisms / Reserved area / Partner card
 *********************************************************************************** */

const bodyScroll = require('body-scroll-toggle');

window.addEventListener('julia:init', () => {

  const className = 'hns-c-partner';

  class PartnerModal {
    constructor(element) {
      this.element = element;
      this.button = this.element.querySelector(':scope > button');

      this.button.onclick = () => {
        this.close();
      }

      this.element.addEventListener('close', this.onclose);
    }

    destroy() {
      if (this.button) {
        this.button.onclick = undefined;
      }
    }

    open() {
      this.element.showModal();
      bodyScroll.disable();
    }

    close() {
      this.element.close();
    }


    // Event listeners

    onclose = (event) => {
      bodyScroll.enable();
    };
  }

  class PartnerCard {
    constructor(element) {
      this.element = element;
      this.button = this.element.querySelector(':scope > header button');
      this.modal = new PartnerModal(this.element.querySelector(':scope > .hns-c-partner-modal'));

      this.button.onclick = () => {
        this.modal.open();
      }
    }

    destroy() {
      if (this.button) {
        this.button.onclick = undefined;
      }
      if (this.modal) {
        this.modal.destroy();
      }
    }
  }

  const initialize = function() {
    document.querySelectorAll(`.${className}`).forEach((partner) => {
      partner.classList.add('hns-m-js');
      partner.hnsPartnerCard = new PartnerCard(partner);
    });
  };

  const destroy = function() {
    document.querySelectorAll(`.${className}`).forEach((partner) => {
      partner.classList.remove('hns-m-js');
      if (partner.hnsPartnerCard) {
        partner.hnsPartnerCard.destroy();
        partner.hnsPartnerCard = undefined;
      }
    });
  };

  window.JULIA.initializers[className] = initialize;
  window.JULIA.destroyers[className] = destroy;

  initialize();
});

/** ***************************************************************************
 * Molecules / Crags / Route table
 **************************************************************************** */

window.addEventListener('julia:init', () => {
  const className = 'hns-c-route-table';

  function openNotes() {
    this.setAttribute('data-status', 'open');
    this.infoToggle.checked = true;
  }

  function closeNotes() {
    this.setAttribute('data-status', 'closed');
    this.infoToggle.checked = false;
  }

  function toggleNotes() {
    this.getAttribute('data-status') === 'open' ? this.close() : this.open();
  }

  function resizeHandler() {
    document.querySelectorAll(`.${className}`).forEach((table) => {
      if (table.routes) {
        table.routes.forEach((route) => {
          route.updateHeight();
        });
      }
    });
  }

  function initialize() {
    document.querySelectorAll(`.${className}`).forEach((table) => {
      table.routes = table.querySelectorAll(':scope > .route');
      table.routes.forEach((route) => {
        route.pitches = route.querySelectorAll(':scope > .pitch.data');

        route.pitches.forEach((pitch) => {
          if (pitch.nextElementSibling?.classList.contains('notes')) {
            pitch.infoNode = pitch.nextElementSibling;
            pitch.infoToggle = pitch.infoNode.querySelector('.hns-c-toggle');
          } else {
            pitch.infoToggle = null;
            pitch.infoNode = null;
          }
          pitch.open = openNotes;
          pitch.close = closeNotes;
          pitch.toggle = toggleNotes;

          pitch.updateHeight = function() {
            if (this.infoNode) {
              const infoContent = this.infoNode.querySelector('.hns-c-toggle + div');
              const value = this.infoNode.scrollHeight + infoContent.scrollHeight;

              this.infoNode.style.setProperty('--height-info', `${value}px`);
            }
          };
          pitch.init = function() {
            if (this.infoToggle) {
              this.infoLabel = this.querySelector('td.notes label');

              this.setAttribute(
                'data-status',
                this.infoToggle.checked ? 'open' : 'closed'
              );

              this.updateHeight();

              this.infoLabel.onclick = (event) => {
                event.preventDefault();
                this.toggle();
              };
            }
          };
        });

        route.infoToggle = route.querySelector(':scope > tr.notes .hns-c-toggle');
        route.open = openNotes;
        route.close = closeNotes;
        route.toggle = toggleNotes;
        route.infoNode = route.querySelector(':scope tr.notes');
        route.updateHeight = function() {

          this.pitches.forEach((pitch) => pitch.updateHeight());
          if (this.infoNode) {
            const infoContent = this.infoNode.querySelector('.hns-c-toggle + div');
            const value = this.infoNode.scrollHeight + infoContent.scrollHeight;

            this.style.setProperty('--height-info', `${value}px`);
          }
        };
        route.init = function() {
          this.pitches.forEach((pitch) => pitch.init());
          if (this.infoToggle) {
            this.infoLabel = this.querySelector('td.notes label');

            this.setAttribute(
              'data-status',
              this.infoToggle.checked ? 'open' : 'closed'
            );

            this.updateHeight();

            this.infoLabel.onclick = (event) => {
              event.preventDefault();
              this.toggle();
            };
          }
        };

        route.init();
      });
    });
    window.addEventListener('resize', resizeHandler);
  }

  function destroy() {
    document.querySelectorAll(`.${className}`).forEach((table) => {
      if (table.routes) {
        table.routes.forEach((route) => {
          route.pitches.forEach((pitch) => {
            pitch.removeAttribute('data-status');
            if (pitch.infoNode) {
              pitch.infoNode.style.removeProperty('--height-info');
              pitch.infoLabel.onclick = undefined;
              pitch.infoLabel = undefined;
            }
            pitch.infoToggle = undefined;
            pitch.infoNode = undefined;
          });
          route.pitches = undefined;
          route.removeAttribute('data-status');
          if (route.infoNode) {
            route.style.removeProperty('--height-info');
            route.infoLabel.onclick = undefined;
            route.infoLabel = undefined;
          }
          route.init = undefined;
          route.infoToggle = undefined;
          route.open = undefined;
          route.close = undefined;
          route.toggle = undefined;
          route.updateHeight = undefined;
        });
      }
      table.routes = undefined;
    });
    window.removeEventListener('resize', resizeHandler);
  }

  window.JULIA.initializers[className] = initialize;
  window.JULIA.destroyers[className] = destroy;

  initialize();
});

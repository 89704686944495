/** ***************************************************************************
 * Molecules / Modal / Base
 *************************************************************************** */

import dialogPolyfill from 'dialog-polyfill';
import Splide from '@splidejs/splide';
import bodyScroll from 'body-scroll-toggle';

window.addEventListener('julia:init', () => {
  const className = 'hns-c-modal';

  function closeHandler(event) {
    bodyScroll.enable();
  }

  function clickHandler(event) {
    if (event.target.nodeName === 'DIALOG') {
      event.target.closeModal();
    }
  }

  function initialize() {
    document.querySelectorAll(`.${className}`).forEach((dialog) => {
      dialog.openModal = function() {
        this.showModal();
        bodyScroll.disable();
      }

      dialog.closeModal = function() {
        this.close();
      }

      dialogPolyfill.registerDialog(dialog);
      dialog.addEventListener('close', closeHandler);
      dialog.addEventListener('click', clickHandler);
      dialog.querySelector('button').onclick = (event) => {
        dialog.closeModal();
      };
    });
  }

  function destroy() {
    document.querySelectorAll(`.${className}`).forEach((dialog) => {
      dialog.querySelector('button').onclick = undefined;
      dialog.openModal = undefined;
      dialog.closeModal = undefined;
      dialog.removeEventListener('close', closeHandler);
      dialog.removeEventListener('click', clickHandler);
    });
  }

  window.JULIA.initializers[className] = initialize;
  window.JULIA.destroyers[className] = destroy;

  initialize();

});

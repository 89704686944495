/** ***************************************************************************
 * Molecules / Modal / Slider
 *************************************************************************** */

import Splide from '@splidejs/splide';

window.addEventListener('julia:init', () => {

  const className = 'hns-c-modal.hns-m-slider';

  function initialize() {
    document.querySelectorAll(`.${className}`).forEach((dialog) => {
      const slider = dialog.querySelector('.splide');
      const slideCount = slider.querySelectorAll('li.splide__slide').length;

      dialog.splide = new Splide(slider, {
        type: 'fade',
        arrows: slideCount > 1,
        rewind: true,
        pagination: false,
        keyboard: true,
        drag: false,
      });
      dialog.splide.mount();
    });
  }

  function destroy() {
    document.querySelectorAll(`.${className}`).forEach((dialog) => {
      if (dialog.splide) {
        dialog.splide.destroy();
      }
    });
  }

  window.JULIA.initializers[className] = initialize;
  window.JULIA.destroyers[className] = destroy;

  initialize();

});

/** ***************************************************************************
 * Molecules / Crags / Sector
 *************************************************************************** */

window.addEventListener('julia:init', () => {

  const className = 'hns-c-sector';

  const headerObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        const sector = entry.target.parentNode;
        sector.classList.toggle('gw-m-floating', !entry.isIntersecting);
      });
    },
  );

  function initialize() {
    document.querySelectorAll(`.${className}`).forEach((sector) => {
      sector.classList.add('hns-m-js');
      sector.sliderButton = sector.querySelector(':scope > header > button');
      sector.floatingSliderButton = sector.querySelector(':scope > aside > button');
      sector.header = sector.querySelector(':scope > header');
      sector.init = function() {

        if (this.sliderButton) {
          const sliderModal = sector.querySelector(':scope > dialog');
          const openModal = () => {
            sliderModal.openModal();
          };
          this.sliderButton.onclick = openModal;
          this.floatingSliderButton.onclick = openModal;
        }

        headerObserver.observe(this.header);
      };

      sector.init();
    });
  }

  function destroy() {
    document.querySelectorAll(`.${className}`).forEach((sector) => {
      sector.classList.remove('hns-m-js');
      sector.classList.remove('hns-m-floating');
      if (sector.header) {
        headerObserver.unobserve(sector.header);
        sector.header = undefined
      }
      if (sector.sliderButton) {
        sector.sliderButton.onclick = undefined;
        sector.floatingSliderButton.onclick = undefined;
        sector.sliderButton = undefined;
        sector.floatingSliderButton = undefined;
      }
      sector.init = undefined;
    });
  }

  window.JULIA.initializers[className] = initialize;
  window.JULIA.destroyers[className] = destroy;

  initialize();
});
